.mdc-dialog.os-dialog.mdc-dialog--open {
  overflow-y: auto; }
  .mdc-dialog.os-dialog.mdc-dialog--open .mdc-dialog__backdrop {
    opacity: .7; }

.mdc-dialog.os-dialog .mdc-dialog__surface {
  margin: 2.1429em auto;
  display: block; }
  .mdc-dialog.os-dialog .mdc-dialog__surface.os-dialog__surface--small {
    min-width: 0;
    max-width: 42.8571em; }
  .mdc-dialog.os-dialog .mdc-dialog__surface .mdc-dialog__body {
    font-size: 1em;
    padding: 1.7143em;
    margin: 0;
    max-height: calc(70vh);
    overflow-y: auto;
    flex: 1; }
    .mdc-dialog.os-dialog .mdc-dialog__surface .mdc-dialog__body--scrollable {
      overflow-y: auto; }
      @media (max-width: 1023px) {
        .mdc-dialog.os-dialog .mdc-dialog__surface .mdc-dialog__body--scrollable {
          max-height: calc(55vh); } }

.mdc-dialog.os-dialog .mdc-dialog__header__title {
  color: #FFF;
  font-family: 'Poppins-Medium';
  font-size: 1.4286em; }

.mdc-dialog.os-dialog .os-dialog__panels .os-dialog__panel {
  display: none; }
  .mdc-dialog.os-dialog .os-dialog__panels .os-dialog__panel--active {
    display: block; }

.mdc-dialog.os-dialog .os-dialog__centered-text {
  text-align: center; }

.mdc-dialog.os-dialog .mdc-dialog__header {
  background-image: linear-gradient(to right, #233363, #02509c);
  color: #FFF;
  padding: 0.8571em 1.7143em;
  position: relative; }
  .mdc-dialog.os-dialog .mdc-dialog__header .os-dialog__header-shape {
    position: absolute;
    right: 0;
    top: 0;
    height: 3.5133em;
    width: 8.0407em;
    overflow: hidden; }
    .mdc-dialog.os-dialog .mdc-dialog__header .os-dialog__header-shape svg {
      width: 100%; }

.mdc-dialog.os-dialog .mdc-dialog__footer {
  border-top: #ECEDEC 1px solid; }

.mdc-dialog.os-dialog.static .mdc-dialog__backdrop {
  pointer-events: none; }

/**************** To Be Removed ***********************/
.dialog-icon-holder {
  margin-bottom: 1.4286em;
  text-align: center; }

.dialog-icon-circle {
  width: 8.8571em; }

.dialog-icon-circle > circle {
  /*fill: #F8F8F8;*/ }

.mdc-dialog-alert .mdc-dialog__footer {
  justify-content: center; }

.mdc-dialog-alert .mdc-dialog__body {
  padding: 3.2143em; }

.dialog-body-title {
  color: #1F344C;
  font-family: 'Poppins-Medium';
  font-size: 1.2857em;
  margin-bottom: 0.4111em; }

.dialog-body-title.dialog-body-title--spaced {
  margin-top: 1.8888em; }

.dialog-body-text {
  font-size: 1.1249em;
  font-family: 'Poppins-Light';
  color: #666667; }

.dialog-body-text.dialog-body-text--spaced {
  margin-top: 1.4375em; }

.dialog-body-text .smaller-text {
  font-size: 0.7777em; }
