ul.os-list {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 2.5em;
  font-weight: normal; }
  ul.os-list li {
    background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='utf-8'?%3E %3C!-- Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E %3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 34 34' enable-background='new 0 0 34 34' xml:space='preserve'%3E %3Cg%3E %3Ccircle fill='%2323AE84' cx='17' cy='17' r='17'/%3E %3Cpath fill='%23FFFFFF' d='M26.8823,5.8294L14.1357,19.3259L9.798,15.578L8.513,16.863l-1.759,1.8334l7.3816,7.1623L27.2092,6.0878 C27.349,5.8744,27.0577,5.6441,26.8823,5.8294z'/%3E %3C/g%3E %3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 0.625em 0.161em;
    background-size: 1.25em 1.25em;
    padding-left: 2.5em;
    margin-bottom: 0.3125em; }

ul.os-list-error {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 2.5em;
  font-weight: normal; }
  ul.os-list-error li {
    background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='utf-8'?%3E %3C!-- Generator: Adobe Illustrator 23.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E %3Csvg version='1.1' id='os-error' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 14 14' style='enable-background:new 0 0 14 14;' xml:space='preserve'%3E %3Cstyle type='text/css'%3E .os-error1%7Bfill:%23EA4335;%7D .os-error2%7Bfill:%23FFFFFF;%7D %3C/style%3E %3Cg%3E %3Cg%3E %3Ccircle class='os-error1' cx='7' cy='7' r='7'/%3E %3C/g%3E %3Cg%3E %3Cpath class='os-error2' d='M9.44,9.86c-0.11,0-0.21-0.04-0.29-0.12L4.26,4.85c-0.16-0.16-0.16-0.42,0-0.58c0.16-0.16,0.42-0.16,0.58,0 l4.89,4.89c0.16,0.16,0.16,0.42,0,0.58C9.66,9.82,9.55,9.86,9.44,9.86z'/%3E %3Cpath class='os-error2' d='M4.56,9.86c-0.11,0-0.21-0.04-0.29-0.12c-0.16-0.16-0.16-0.42,0-0.58l4.89-4.89c0.16-0.16,0.42-0.16,0.58,0 c0.16,0.16,0.16,0.42,0,0.58L4.85,9.74C4.77,9.82,4.66,9.86,4.56,9.86z'/%3E %3C/g%3E %3C/g%3E %3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 0.625em 0.161em;
    background-size: 1.25em 1.25em;
    padding-left: 2.5em;
    margin-bottom: 0.3125em; }

ol.os-numbered-list {
  list-style: none;
  counter-reset: item;
  margin-left: 2.6em; }
  ol.os-numbered-list li {
    margin-left: -2.6em;
    counter-increment: item;
    margin-bottom: 5px; }
  ol.os-numbered-list li:before {
    margin-right: 10px;
    content: counter(item);
    background: #23ae84;
    border-radius: 100%;
    color: white;
    width: 1.6em;
    height: 1.6em;
    line-height: 1.7em;
    text-align: center;
    display: inline-block;
    margin-left: -2.6em; }
