.os-data-table {
  margin: 0 auto;
  position: relative;
  border-collapse: collapse;
  white-space: nowrap;
  font-size: 1em;
  background-color: rgb(#FFF);
  width: 100%; }
  @media (max-width: 479px) {
    .os-data-table {
      white-space: normal;
      font-size: 0.9em; } }
  .os-data-table thead {
    padding-bottom: 3px;
    font-family: "Poppins-Light"; }
    .os-data-table thead .os-data-table__select {
      margin-top: 0; }
  .os-data-table tbody tr {
    position: relative;
    height: 48px;
    transition-duration: 0.28s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-property: background-color;
    /* &:hover {
				background-color: #eeeeee;
			} */ }
    .os-data-table tbody tr.is-selected {
      background-color: #e0e0e0; }
  .os-data-table td, .os-data-table th {
    padding: 0 0.667em 0.833em 0.667em;
    text-align: left;
    /* &:first-of-type {
			padding-left: 1.667em;
		}

		&:last-of-type {
			padding-right: 1.667em;
		} */ }
  .os-data-table td {
    position: relative;
    vertical-align: bottom;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding-top: 0.833em;
    box-sizing: border-box; }
    .os-data-table td .os-data-table__select {
      vertical-align: middle; }
  .os-data-table th {
    color: #004f9f;
    text-transform: uppercase;
    position: relative;
    vertical-align: bottom;
    text-overflow: ellipsis;
    font-size: 1.25em;
    padding-bottom: 1.5em;
    box-sizing: border-box; }
    .os-data-table th.os-data-table__header--sorted-ascending, .os-data-table th.os-data-table__header--sorted-descending {
      color: rgba(0, 0, 0, 0.87); }
      .os-data-table th.os-data-table__header--sorted-ascending:before, .os-data-table th.os-data-table__header--sorted-descending:before {
        font-size: 1em;
        content: "\E5D8";
        margin-right: 5px;
        vertical-align: sub; }
      .os-data-table th.os-data-table__header--sorted-ascending:hover, .os-data-table th.os-data-table__header--sorted-descending:hover {
        cursor: pointer; }
        .os-data-table th.os-data-table__header--sorted-ascending:hover:before, .os-data-table th.os-data-table__header--sorted-descending:hover:before {
          color: rgba(0, 0, 0, 0.26); }
    .os-data-table th.os-data-table__header--sorted-descending:before {
      content: "\E5DB"; }

.os-data-table__select {
  width: 16px; }

.os-data-table__cell--non-numeric.os-data-table__cell--non-numeric {
  text-align: left; }
