.footer-icon-list {
  position: relative;
  padding: 0;
  width: 95%;
  height: 3em;
  text-align: right; }

.footer-icon-list__container {
  width: 3em;
  height: 3em;
  position: relative;
  display: inline-block;
  margin: 0 0.5em;
  top: -1em; }

.footer-icon-list__container.partner-modal {
  max-width: 10em;
  width: 100%; }
  .footer-icon-list__container.partner-modal .footer-icon-list__icon {
    width: 10em; }

.footer-icon-list__icon {
  width: 3em;
  height: 3em; }
  .footer-icon-list__icon .footer-icon-social {
    stroke: none; }

.partner-modal-container--small {
  display: none; }

@media (min-width: 480px) and (max-width: 839px) {
  .footer-icon-list {
    margin-top: 0.5em;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
  .footer-icon-list li {
    display: block;
    flex: 0 1 auto; } }

@media (max-width: 479px) {
  .footer-icon-list {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 90%; }
  .footer-icon-list li {
    flex: 0 1 auto; } }

@media (max-width: 839px) {
  .footer-icon-list {
    text-align: left; }
  .footer-icon-list__container {
    margin: 0 0.75em; }
  .partner-modal--large {
    display: none !important; }
  .partner-modal-container--small {
    display: flex;
    padding: 0.75em; } }

.footer-icon-list #youtube_icon:hover .footer-icon-social {
  fill: #FE0000; }

.footer-icon-list #twitter_icon:hover .footer-icon-social {
  fill: #1DA0F1; }

.footer-icon-list #facebook_icon:hover .footer-icon-social {
  fill: #004F9F; }

.footer-icon-list #google_icon:hover .footer-icon-social {
  fill: #DF4F3C; }

.footer-icon-list #linked_in_icon:hover .footer-icon-social {
  fill: #0077B4; }
