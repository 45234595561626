.bottom-block {
  position: relative;
  z-index: -2;
  width: 100%;
  height: 30em;
  margin-top: -20em;
  background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='utf-8'?%3E %3C!-- Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E %3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 1920 475.7805' enable-background='new 0 0 1920 475.7805' xml:space='preserve' preserveAspectRatio='xMinYMax'%3E %3Cg%3E %3Cg%3E %3ClinearGradient id='SVGID_1_' gradientUnits='userSpaceOnUse' x1='1063.3622' y1='293.9104' x2='100.6749' y2='662.2429'%3E %3Cstop offset='0' style='stop-color:%23102D69'/%3E %3Cstop offset='0.2839' style='stop-color:%230F2F6D'/%3E %3Cstop offset='0.5466' style='stop-color:%230C3678'/%3E %3Cstop offset='0.8001' style='stop-color:%2306428B'/%3E %3Cstop offset='1' style='stop-color:%23004F9F'/%3E %3C/linearGradient%3E %3Cpolygon fill='url(%23SVGID_1_)' points='0,191.1348 0,475.7805 1920,475.7805 1920,0 '/%3E %3C/g%3E %3Cg%3E %3ClinearGradient id='SVGID_2_' gradientUnits='userSpaceOnUse' x1='897.0391' y1='388.7332' x2='135.862' y2='-0.0303'%3E %3Cstop offset='0' style='stop-color:%23102D69'/%3E %3Cstop offset='0.2798' style='stop-color:%230D3373'/%3E %3Cstop offset='0.7584' style='stop-color:%2305458F'/%3E %3Cstop offset='1' style='stop-color:%23004F9F'/%3E %3C/linearGradient%3E %3Cpolygon fill='url(%23SVGID_2_)' points='1920,365.9992 1920,475.7805 0,475.7805 0,194.4252 '/%3E %3C/g%3E %3Cg opacity='0.4'%3E %3Cpolygon fill='%230B1742' points='1920,474.0998 1920,475.7805 0.033,475.7805 0.033,282.9651 '/%3E %3C/g%3E %3Cg opacity='0.3'%3E %3Cpolygon fill='%230B1742' points='0,349.3505 0,475.7805 1920,475.2902 1920,294.0869 '/%3E %3C/g%3E %3C/g%3E %3Cg%3E %3Cpolygon fill='%230E2050' points='0,442.1451 0,475.7805 1920,475.7805 1920,475.7805 '/%3E %3C/g%3E %3C/svg%3E");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-top: left bottom; }
