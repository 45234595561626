@font-face {
  font-family: 'Roboto-BoldItalic';
  src: url("/font/roboto/Roboto-BoldItalic.eot");
  src: url("/font/roboto/Roboto-BoldItalic.eot?#iefix") format("embedded-opentype"), url("/font/roboto/Roboto-BoldItalic.woff") format("woff"), url("/font/roboto/Roboto-BoldItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-BlackItalic';
  src: url("/font/roboto/Roboto-BlackItalic.eot");
  src: url("/font/roboto/Roboto-BlackItalic.eot?#iefix") format("embedded-opentype"), url("/font/roboto/Roboto-BlackItalic.woff") format("woff"), url("/font/roboto/Roboto-BlackItalic.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: 'Roboto-Light';
  src: url("/font/roboto/Roboto-Light.eot");
  src: url("/font/roboto/Roboto-Light.eot?#iefix") format("embedded-opentype"), url("/font/roboto/Roboto-Light.woff") format("woff"), url("/font/roboto/Roboto-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-Thin';
  src: url("/font/roboto/Roboto-Thin.eot");
  src: url("/font/roboto/Roboto-Thin.eot?#iefix") format("embedded-opentype"), url("/font/roboto/Roboto-Thin.woff") format("woff"), url("/font/roboto/Roboto-Thin.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-MediumItalic';
  src: url("/font/roboto/Roboto-MediumItalic.eot");
  src: url("/font/roboto/Roboto-MediumItalic.eot?#iefix") format("embedded-opentype"), url("/font/roboto/Roboto-MediumItalic.woff") format("woff"), url("/font/roboto/Roboto-MediumItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-Bold';
  src: url("/font/roboto/Roboto-Bold.eot");
  src: url("/font/roboto/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("/font/roboto/Roboto-Bold.woff") format("woff"), url("/font/roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-LightItalic';
  src: url("/font/roboto/Roboto-LightItalic.eot");
  src: url("/font/roboto/Roboto-LightItalic.eot?#iefix") format("embedded-opentype"), url("/font/roboto/Roboto-LightItalic.woff") format("woff"), url("/font/roboto/Roboto-LightItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-Medium';
  src: url("/font/roboto/Roboto-Medium.eot");
  src: url("/font/roboto/Roboto-Medium.eot?#iefix") format("embedded-opentype"), url("/font/roboto/Roboto-Medium.woff") format("woff"), url("/font/roboto/Roboto-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-Regular';
  src: url("/font/roboto/Roboto-Regular.eot");
  src: url("/font/roboto/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("/font/roboto/Roboto-Regular.woff") format("woff"), url("/font/roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-ThinItalic';
  src: url("/font/roboto/Roboto-ThinItalic.eot");
  src: url("/font/roboto/Roboto-ThinItalic.eot?#iefix") format("embedded-opentype"), url("/font/roboto/Roboto-ThinItalic.woff") format("woff"), url("/font/roboto/Roboto-ThinItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-Black';
  src: url("/font/roboto/Roboto-Black.eot");
  src: url("/font/roboto/Roboto-Black.eot?#iefix") format("embedded-opentype"), url("/font/roboto/Roboto-Black.woff") format("woff"), url("/font/roboto/Roboto-Black.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-Italic';
  src: url("/font/roboto/Roboto-Italic.eot");
  src: url("/font/roboto/Roboto-Italic.eot?#iefix") format("embedded-opentype"), url("/font/roboto/Roboto-Italic.woff") format("woff"), url("/font/roboto/Roboto-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-Light';
  src: url("/font/poppins/Poppins-Light.eot");
  src: url("/font/poppins/Poppins-Light.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-Light.woff") format("woff"), url("/font/poppins/Poppins-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-SemiBold';
  src: url("/font/poppins/Poppins-SemiBold.eot");
  src: url("/font/poppins/Poppins-SemiBold.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-SemiBold.woff") format("woff"), url("/font/poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-ExtraLight';
  src: url("/font/poppins/Poppins-ExtraLight.eot");
  src: url("/font/poppins/Poppins-ExtraLight.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-ExtraLight.woff") format("woff"), url("/font/poppins/Poppins-ExtraLight.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-Italic';
  src: url("/font/poppins/Poppins-Italic.eot");
  src: url("/font/poppins/Poppins-Italic.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-Italic.woff") format("woff"), url("/font/poppins/Poppins-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-Bold';
  src: url("/font/poppins/Poppins-Bold.eot");
  src: url("/font/poppins/Poppins-Bold.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-Bold.woff") format("woff"), url("/font/poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-ExtraBoldItalic';
  src: url("/font/poppins/Poppins-ExtraBoldItalic.eot");
  src: url("/font/poppins/Poppins-ExtraBoldItalic.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-ExtraBoldItalic.woff") format("woff"), url("/font/poppins/Poppins-ExtraBoldItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-ExtraBold';
  src: url("/font/poppins/Poppins-ExtraBold.eot");
  src: url("/font/poppins/Poppins-ExtraBold.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-ExtraBold.woff") format("woff"), url("/font/poppins/Poppins-ExtraBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-ExtraLightItalic';
  src: url("/font/poppins/Poppins-ExtraLightItalic.eot");
  src: url("/font/poppins/Poppins-ExtraLightItalic.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-ExtraLightItalic.woff") format("woff"), url("/font/poppins/Poppins-ExtraLightItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-Medium';
  src: url("/font/poppins/Poppins-Medium.eot");
  src: url("/font/poppins/Poppins-Medium.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-Medium.woff") format("woff"), url("/font/poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-Black';
  src: url("/font/poppins/Poppins-Black.eot");
  src: url("/font/poppins/Poppins-Black.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-Black.woff") format("woff"), url("/font/poppins/Poppins-Black.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-BlackItalic';
  src: url("/font/poppins/Poppins-BlackItalic.eot");
  src: url("/font/poppins/Poppins-BlackItalic.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-BlackItalic.woff") format("woff"), url("/font/poppins/Poppins-BlackItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-BoldItalic';
  src: url("/font/poppins/Poppins-BoldItalic.eot");
  src: url("/font/poppins/Poppins-BoldItalic.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-BoldItalic.woff") format("woff"), url("/font/poppins/Poppins-BoldItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-ThinItalic';
  src: url("/font/poppins/Poppins-ThinItalic.eot");
  src: url("/font/poppins/Poppins-ThinItalic.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-ThinItalic.woff") format("woff"), url("/font/poppins/Poppins-ThinItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-Thin';
  src: url("/font/poppins/Poppins-Thin.eot");
  src: url("/font/poppins/Poppins-Thin.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-Thin.woff") format("woff"), url("/font/poppins/Poppins-Thin.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-MediumItalic';
  src: url("/font/poppins/Poppins-MediumItalic.eot");
  src: url("/font/poppins/Poppins-MediumItalic.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-MediumItalic.woff") format("woff"), url("/font/poppins/Poppins-MediumItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-Regular';
  src: url("/font/poppins/Poppins-Regular.eot");
  src: url("/font/poppins/Poppins-Regular.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-Regular.woff") format("woff"), url("/font/poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-SemiBoldItalic';
  src: url("/font/poppins/Poppins-SemiBoldItalic.eot");
  src: url("/font/poppins/Poppins-SemiBoldItalic.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-SemiBoldItalic.woff") format("woff"), url("/font/poppins/Poppins-SemiBoldItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-LightItalic';
  src: url("/font/poppins/Poppins-LightItalic.eot");
  src: url("/font/poppins/Poppins-LightItalic.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-LightItalic.woff") format("woff"), url("/font/poppins/Poppins-LightItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@keyframes mdc-ripple-fg-radius-in {
  from {
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1); }
  to {
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); } }

@keyframes mdc-ripple-fg-opacity-in {
  from {
    animation-timing-function: linear;
    opacity: 0; }
  to {
    opacity: var(--mdc-ripple-fg-opacity, 0); } }

@keyframes mdc-ripple-fg-opacity-out {
  from {
    animation-timing-function: linear;
    opacity: var(--mdc-ripple-fg-opacity, 0); }
  to {
    opacity: 0; } }

.mdc-ripple-surface--test-edge-var-bug {
  --mdc-ripple-surface-test-edge-var: 1px solid #000;
  visibility: hidden; }
  .mdc-ripple-surface--test-edge-var-bug::before {
    border: var(--mdc-ripple-surface-test-edge-var); }

.mdc-button {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 2.25rem;
  font-weight: 500;
  letter-spacing: 0.08929em;
  text-decoration: none;
  text-transform: uppercase;
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  padding: 0 8px 0 8px;
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-width: 64px;
  height: 36px;
  border: none;
  outline: none;
  /* @alternate */
  line-height: inherit;
  user-select: none;
  -webkit-appearance: none;
  overflow: hidden;
  vertical-align: middle;
  border-radius: 2px; }
  .mdc-button::before, .mdc-button::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  .mdc-button::before {
    transition: opacity 15ms linear;
    z-index: 1; }
  .mdc-button.mdc-ripple-upgraded::before {
    transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-button.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    transform: scale(0);
    transform-origin: center center; }
  .mdc-button.mdc-ripple-upgraded--unbounded::after {
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: var(--mdc-ripple-left, 0); }
  .mdc-button.mdc-ripple-upgraded--foreground-activation::after {
    animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
  .mdc-button.mdc-ripple-upgraded--foreground-deactivation::after {
    animation: 150ms mdc-ripple-fg-opacity-out;
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-button::before, .mdc-button::after {
    top: calc(50% - 100%);
    /* @noflip */
    left: calc(50% - 100%);
    width: 200%;
    height: 200%; }
  .mdc-button.mdc-ripple-upgraded::after {
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-button::-moz-focus-inner {
    padding: 0;
    border: 0; }
  .mdc-button:active {
    outline: none; }
  .mdc-button:hover {
    cursor: pointer; }
  .mdc-button:disabled {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.37);
    cursor: default;
    pointer-events: none; }
  .mdc-button:not(:disabled) {
    background-color: transparent; }
  .mdc-button:not(:disabled) {
    color: #6200ee;
    /* @alternate */
    color: var(--mdc-theme-primary, #6200ee); }
  .mdc-button::before, .mdc-button::after {
    background-color: #6200ee; }
    @supports not (-ms-ime-align: auto) {
      .mdc-button::before, .mdc-button::after {
        /* @alternate */
        background-color: var(--mdc-theme-primary, #6200ee); } }
  .mdc-button:hover::before {
    opacity: 0.04; }
  .mdc-button:not(.mdc-ripple-upgraded):focus::before, .mdc-button.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-button:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .mdc-button:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.16; }
  .mdc-button.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.16; }
  .mdc-button .mdc-button__icon {
    /* @noflip */
    margin-left: 0;
    /* @noflip */
    margin-right: 8px;
    display: inline-block;
    width: 18px;
    height: 18px;
    font-size: 18px;
    vertical-align: top; }
    [dir="rtl"] .mdc-button .mdc-button__icon, .mdc-button .mdc-button__icon[dir="rtl"] {
      /* @noflip */
      margin-left: 8px;
      /* @noflip */
      margin-right: 0; }
  .mdc-button svg.mdc-button__icon {
    fill: currentColor; }

.mdc-button--raised .mdc-button__icon,
.mdc-button--unelevated .mdc-button__icon,
.mdc-button--outlined .mdc-button__icon {
  /* @noflip */
  margin-left: -4px;
  /* @noflip */
  margin-right: 8px; }
  [dir="rtl"] .mdc-button--raised .mdc-button__icon, .mdc-button--raised .mdc-button__icon[dir="rtl"], [dir="rtl"]
  .mdc-button--unelevated .mdc-button__icon,
  .mdc-button--unelevated .mdc-button__icon[dir="rtl"], [dir="rtl"]
  .mdc-button--outlined .mdc-button__icon,
  .mdc-button--outlined .mdc-button__icon[dir="rtl"] {
    /* @noflip */
    margin-left: 8px;
    /* @noflip */
    margin-right: -4px; }

.mdc-button--raised,
.mdc-button--unelevated {
  padding: 0 16px 0 16px; }
  .mdc-button--raised:disabled,
  .mdc-button--unelevated:disabled {
    background-color: rgba(0, 0, 0, 0.12);
    color: rgba(0, 0, 0, 0.37); }
  .mdc-button--raised:not(:disabled),
  .mdc-button--unelevated:not(:disabled) {
    background-color: #6200ee; }
    @supports not (-ms-ime-align: auto) {
      .mdc-button--raised:not(:disabled),
      .mdc-button--unelevated:not(:disabled) {
        /* @alternate */
        background-color: var(--mdc-theme-primary, #6200ee); } }
  .mdc-button--raised:not(:disabled),
  .mdc-button--unelevated:not(:disabled) {
    color: #fff;
    /* @alternate */
    color: var(--mdc-theme-on-primary, #fff); }
  .mdc-button--raised::before, .mdc-button--raised::after,
  .mdc-button--unelevated::before,
  .mdc-button--unelevated::after {
    background-color: #fff; }
    @supports not (-ms-ime-align: auto) {
      .mdc-button--raised::before, .mdc-button--raised::after,
      .mdc-button--unelevated::before,
      .mdc-button--unelevated::after {
        /* @alternate */
        background-color: var(--mdc-theme-on-primary, #fff); } }
  .mdc-button--raised:hover::before,
  .mdc-button--unelevated:hover::before {
    opacity: 0.08; }
  .mdc-button--raised:not(.mdc-ripple-upgraded):focus::before, .mdc-button--raised.mdc-ripple-upgraded--background-focused::before,
  .mdc-button--unelevated:not(.mdc-ripple-upgraded):focus::before,
  .mdc-button--unelevated.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.24; }
  .mdc-button--raised:not(.mdc-ripple-upgraded)::after,
  .mdc-button--unelevated:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .mdc-button--raised:not(.mdc-ripple-upgraded):active::after,
  .mdc-button--unelevated:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.32; }
  .mdc-button--raised.mdc-ripple-upgraded,
  .mdc-button--unelevated.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.32; }

.mdc-button--raised {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1); }
  .mdc-button--raised:hover, .mdc-button--raised:focus {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .mdc-button--raised:active {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }
  .mdc-button--raised:disabled {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }

.mdc-button--outlined {
  border-style: solid;
  padding: 0 14px 0 14px;
  border-width: 2px; }
  .mdc-button--outlined:disabled {
    border-color: rgba(0, 0, 0, 0.37); }
  .mdc-button--outlined:not(:disabled) {
    border-color: #6200ee;
    /* @alternate */
    border-color: var(--mdc-theme-primary, #6200ee); }

.mdc-button--dense {
  height: 32px;
  font-size: .8125rem; }

.mdc-button.os-button--primary {
  font-family: 'Poppins-Regular'; }
  .mdc-button.os-button--primary:not(:disabled) {
    background-color: #004f9f; }
  .mdc-button.os-button--primary:not(:disabled) {
    color: white;
    /* @alternate */
    color: var(--mdc-theme-text-primary-on-dark, white); }
  .mdc-button.os-button--primary::before, .mdc-button.os-button--primary::after {
    background-color: white; }
    @supports not (-ms-ime-align: auto) {
      .mdc-button.os-button--primary::before, .mdc-button.os-button--primary::after {
        /* @alternate */
        background-color: var(--mdc-theme-text-primary-on-dark, white); } }
  .mdc-button.os-button--primary:hover::before {
    opacity: 0.08; }
  .mdc-button.os-button--primary:not(.mdc-ripple-upgraded):focus::before, .mdc-button.os-button--primary.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.24; }
  .mdc-button.os-button--primary:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .mdc-button.os-button--primary:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.32; }
  .mdc-button.os-button--primary.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.32; }

.mdc-button.os-button--primary-outlined {
  font-family: 'Poppins-Regular'; }
  .mdc-button.os-button--primary-outlined::before, .mdc-button.os-button--primary-outlined::after {
    background-color: #004f9f; }
  .mdc-button.os-button--primary-outlined:hover::before {
    opacity: 0.04; }
  .mdc-button.os-button--primary-outlined:not(.mdc-ripple-upgraded):focus::before, .mdc-button.os-button--primary-outlined.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-button.os-button--primary-outlined:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .mdc-button.os-button--primary-outlined:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.16; }
  .mdc-button.os-button--primary-outlined.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.16; }
  .mdc-button.os-button--primary-outlined:not(:disabled) {
    color: #004f9f; }
  .mdc-button.os-button--primary-outlined:not(:disabled) {
    border-color: #004f9f; }

.mdc-button.os-button--primary-inverted-outlined {
  font-family: 'Poppins-Regular'; }
  .mdc-button.os-button--primary-inverted-outlined::before, .mdc-button.os-button--primary-inverted-outlined::after {
    background-color: #004f9f; }
  .mdc-button.os-button--primary-inverted-outlined:hover::before {
    opacity: 0.04; }
  .mdc-button.os-button--primary-inverted-outlined:not(.mdc-ripple-upgraded):focus::before, .mdc-button.os-button--primary-inverted-outlined.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-button.os-button--primary-inverted-outlined:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .mdc-button.os-button--primary-inverted-outlined:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.16; }
  .mdc-button.os-button--primary-inverted-outlined.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.16; }
  .mdc-button.os-button--primary-inverted-outlined:not(:disabled) {
    color: #FFF; }
  .mdc-button.os-button--primary-inverted-outlined:not(:disabled) {
    border-color: #FFF; }
  .mdc-button.os-button--primary-inverted-outlined:hover:not(:disabled) {
    color: #004f9f; }
  .mdc-button.os-button--primary-inverted-outlined:hover:not(:disabled) {
    background-color: #FFF; }

.mdc-button.os-button--primary-opaque-outlined {
  font-family: 'Poppins-Regular'; }
  .mdc-button.os-button--primary-opaque-outlined::before, .mdc-button.os-button--primary-opaque-outlined::after {
    background-color: #004f9f; }
  .mdc-button.os-button--primary-opaque-outlined:hover::before {
    opacity: 0.04; }
  .mdc-button.os-button--primary-opaque-outlined:not(.mdc-ripple-upgraded):focus::before, .mdc-button.os-button--primary-opaque-outlined.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-button.os-button--primary-opaque-outlined:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .mdc-button.os-button--primary-opaque-outlined:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.16; }
  .mdc-button.os-button--primary-opaque-outlined.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.16; }
  .mdc-button.os-button--primary-opaque-outlined:not(:disabled) {
    color: #004f9f; }
  .mdc-button.os-button--primary-opaque-outlined:not(:disabled) {
    background-color: #FFF; }
  .mdc-button.os-button--primary-opaque-outlined:not(:disabled) {
    border-color: #004f9f; }

.mdc-button.os-button--action {
  font-family: 'Poppins-Regular'; }
  .mdc-button.os-button--action:not(:disabled) {
    background-color: #ffd500; }
  .mdc-button.os-button--action:not(:disabled) {
    color: rgba(0, 0, 0, 0.87);
    /* @alternate */
    color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87)); }
  .mdc-button.os-button--action::before, .mdc-button.os-button--action::after {
    background-color: rgba(0, 0, 0, 0.87); }
    @supports not (-ms-ime-align: auto) {
      .mdc-button.os-button--action::before, .mdc-button.os-button--action::after {
        /* @alternate */
        background-color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87)); } }
  .mdc-button.os-button--action:hover::before {
    opacity: 0.04; }
  .mdc-button.os-button--action:not(.mdc-ripple-upgraded):focus::before, .mdc-button.os-button--action.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-button.os-button--action:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .mdc-button.os-button--action:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.16; }
  .mdc-button.os-button--action.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.16; }

.mdc-button.os-button--secondary-action:not(:disabled) {
  background-color: #23ae84; }

.mdc-button.os-button--secondary-action:not(:disabled) {
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87)); }

.mdc-button.os-button--secondary-action::before, .mdc-button.os-button--secondary-action::after {
  background-color: rgba(0, 0, 0, 0.87); }
  @supports not (-ms-ime-align: auto) {
    .mdc-button.os-button--secondary-action::before, .mdc-button.os-button--secondary-action::after {
      /* @alternate */
      background-color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87)); } }

.mdc-button.os-button--secondary-action:hover::before {
  opacity: 0.04; }

.mdc-button.os-button--secondary-action:not(.mdc-ripple-upgraded):focus::before, .mdc-button.os-button--secondary-action.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.12; }

.mdc-button.os-button--secondary-action:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear; }

.mdc-button.os-button--secondary-action:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.16; }

.mdc-button.os-button--secondary-action.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.16; }

.mdc-button.os-button--secondary-action:not(:disabled) {
  color: #FFF; }

.mdc-button.os-button--inverted-secondary-action:not(:disabled) {
  background-color: #FFF; }

.mdc-button.os-button--inverted-secondary-action:not(:disabled) {
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87)); }

.mdc-button.os-button--inverted-secondary-action::before, .mdc-button.os-button--inverted-secondary-action::after {
  background-color: rgba(0, 0, 0, 0.87); }
  @supports not (-ms-ime-align: auto) {
    .mdc-button.os-button--inverted-secondary-action::before, .mdc-button.os-button--inverted-secondary-action::after {
      /* @alternate */
      background-color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87)); } }

.mdc-button.os-button--inverted-secondary-action:hover::before {
  opacity: 0.04; }

.mdc-button.os-button--inverted-secondary-action:not(.mdc-ripple-upgraded):focus::before, .mdc-button.os-button--inverted-secondary-action.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.12; }

.mdc-button.os-button--inverted-secondary-action:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear; }

.mdc-button.os-button--inverted-secondary-action:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.16; }

.mdc-button.os-button--inverted-secondary-action.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.16; }

.mdc-button.os-button--inverted-secondary-action:not(:disabled) {
  color: #23ae84; }
