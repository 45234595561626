.news-inbrief {
  border: 2px solid #C0D5ED;
  padding: 15px 20px 15px 20px;
  background-color: #FFF;
  margin-bottom: 25px; }

.news-inbrief__header {
  pointer-events: none;
  text-decoration: none; }
  @media (max-width: 479px) {
    .news-inbrief__header {
      background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='utf-8'?%3E %3C!-- Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E %3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 8 13.745' enable-background='new 0 0 8 13.745' xml:space='preserve'%3E %3Cg%3E %3Cpath fill='%23C0D5ED' d='M1.5034,13.5302C1.396,13.6734,1.2528,13.745,1.0738,13.745s-0.34-0.0537-0.4832-0.1611l-0.3758-0.4295 C0.0716,13.047,0,12.9038,0,12.7248c0-0.179,0.0716-0.34,0.2148-0.4832l5.4765-5.3691L0.2148,1.5034 C0.1074,1.3602,0.0537,1.1991,0.0537,1.0201S0.1074,0.698,0.2148,0.5906l0.3758-0.4295C0.7338,0.0537,0.8949,0,1.0738,0 S1.396,0.0537,1.5034,0.1611l6.3356,6.2282C7.9463,6.5324,8,6.6935,8,6.8725s-0.0537,0.34-0.1611,0.4832V7.302L1.5034,13.5302z'/%3E %3C/g%3E %3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right center;
      background-size: 1em;
      text-transform: uppercase;
      margin-left: 0;
      pointer-events: auto; }
      .news-inbrief__header.active {
        background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='utf-8'?%3E %3C!-- Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E %3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 13.745 8' enable-background='new 0 0 13.745 8' xml:space='preserve'%3E %3Cg%3E %3Cpath fill='%23C0D5ED' d='M0.2148,1.5034C0.0716,1.396,0,1.2528,0,1.0738s0.0537-0.34,0.1611-0.4832l0.4295-0.3758 C0.698,0.0716,0.8412,0,1.0201,0s0.34,0.0716,0.4832,0.2148l5.3691,5.4765l5.3691-5.4765 c0.1432-0.1074,0.3043-0.1611,0.4832-0.1611c0.179,0,0.3221,0.0537,0.4295,0.1611l0.4295,0.3758 c0.1074,0.1432,0.1611,0.3043,0.1611,0.4832s-0.0537,0.3221-0.1611,0.4295L7.3557,7.8389C7.2125,7.9463,7.0515,8,6.8725,8 s-0.34-0.0537-0.4832-0.1611H6.443L0.2148,1.5034z'/%3E %3C/g%3E %3C/svg%3E");
        background-size: 2em; }
      .news-inbrief__header:hover {
        cursor: pointer; } }

.news-inbrief__item {
  margin-bottom: 15px;
  border-bottom: 2px solid #C0D5ED;
  padding: 0 8px 0 8px;
  position: relative; }
  .news-inbrief__item a {
    text-decoration: none;
    font-family: 'Roboto-Medium';
    color: #0b1742; }
  .news-inbrief__item .blue-border {
    position: absolute;
    width: 5px;
    top: 0;
    bottom: 20%;
    left: 0;
    background-color: #004f9f; }
  .news-inbrief__item .inbrief-button {
    height: auto;
    text-align: left;
    padding-left: 15px;
    padding-bottom: 15px;
    vertical-align: top;
    display: block; }
