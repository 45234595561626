.contact-bottom-block {
  position: relative;
  margin-top: -15em;
  z-index: -2;
  width: 100%;
  height: 30em;
  background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='utf-8'?%3E %3C!-- Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E %3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 1920.0165 477.7805' enable-background='new 0 0 1920.0165 477.7805' xml:space='preserve' preserveAspectRatio='none'%3E %3Crect x='0.0165' fill='%23FFFFFF' width='1920' height='194.4252'/%3E %3Cg%3E %3Cg%3E %3ClinearGradient id='SVGID_1_' gradientUnits='userSpaceOnUse' x1='1063.3622' y1='186.0896' x2='100.6749' y2='-182.2429' gradientTransform='matrix(1 0 0 -1 0 482)'%3E %3Cstop offset='0' style='stop-color:%23102D69'/%3E %3Cstop offset='0.2839' style='stop-color:%230F2F6D'/%3E %3Cstop offset='0.5466' style='stop-color:%230C3678'/%3E %3Cstop offset='0.8001' style='stop-color:%2306428B'/%3E %3Cstop offset='1' style='stop-color:%23004F9F'/%3E %3C/linearGradient%3E %3Cpolygon fill='url(%23SVGID_1_)' points='0,193.1348 0,477.7805 1920,477.7805 1920,2 '/%3E %3C/g%3E %3Cg%3E %3ClinearGradient id='SVGID_2_' gradientUnits='userSpaceOnUse' x1='897.0391' y1='91.2668' x2='135.862' y2='480.0302' gradientTransform='matrix(1 0 0 -1 0 482)'%3E %3Cstop offset='0' style='stop-color:%23102D69'/%3E %3Cstop offset='0.2798' style='stop-color:%230D3373'/%3E %3Cstop offset='0.7584' style='stop-color:%2305458F'/%3E %3Cstop offset='1' style='stop-color:%23004F9F'/%3E %3C/linearGradient%3E %3Cpolygon fill='url(%23SVGID_2_)' points='1920,367.9992 1920,477.7805 0,477.7805 0,196.4252 '/%3E %3C/g%3E %3Cg opacity='0.4'%3E %3Cpolygon fill='%230B1742' points='1920,476.0998 1920,477.7805 0.033,477.7805 0.033,284.9651 '/%3E %3C/g%3E %3Cg opacity='0.3'%3E %3Cpolygon fill='%230B1742' points='0,351.3505 0,477.7805 1920,477.2902 1920,296.0869 '/%3E %3C/g%3E %3C/g%3E %3Cg%3E %3Cpolygon fill='%230E2050' points='0,444.1451 0,477.7805 1920,477.7805 1920,477.7805 '/%3E %3C/g%3E %3C/svg%3E");
  background-repeat: no-repeat;
  background-position: left bottom; }
  @media (min-width: 480px) and (max-width: 839px) {
    .contact-bottom-block {
      height: 17em; } }
  @media (max-width: 479px) {
    .contact-bottom-block {
      height: 7em;
      margin-top: -5em; } }
