.job-posting-top-image {
  position: absolute;
  width: 100%;
  height: 50em;
  z-index: -2;
  background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='utf-8'?%3E %3C!-- Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E %3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 1920.5986 771.4928' enable-background='new 0 0 1920.5986 771.4928' xml:space='preserve' preserveAspectRatio='xMinYMax'%3E %3Cg%3E %3Cpolygon fill='%23DCE7F5' points='0,0 0,771.4928 1920.5986,580.3579 1920.5986,0 '/%3E %3Cpolygon fill='%23ECF3FB' points='1726.9133,549.9863 1920.7861,530.6855 1920.7861,480.9199 1731.5531,499.7578 '/%3E %3Cpolygon fill='%23C0D5ED' points='1478.6556,574.6788 1474.0157,624.9064 1789.0591,593.5441 1793.699,543.3156 '/%3E %3Cpolygon opacity='0.5' fill='%23C0D5ED' points='475.2773,577.4272 207.3596,604.0991 202.7197,654.3267 470.6375,627.6548 '/%3E %3Cpolygon fill='%23C0D5ED' points='83.8885,713.5055 79.2486,763.733 308.8045,740.8805 313.4444,690.653 '/%3E %3Cpolygon opacity='0.5' fill='%23C0D5ED' points='1582.5417,514.5916 1731.5157,499.7605 1736.1556,449.533 1587.1816,464.364 '/%3E %3Cpolygon opacity='0.75' fill='%23ECF3FB' points='127.1157,562.1141 122.4758,612.3416 207.9632,603.8318 212.6031,553.6043 '/%3E %3Cpolygon fill='none' points='1920.5986,580.358 1920.5986,0 0.6316,0 0.6316,771.4928 '/%3E %3Cpolygon opacity='0.75' fill='%23ECF3FB' points='-0.0972,396.4696 -0.0972,446.2352 119.8854,434.2906 124.5253,384.0631 '/%3E %3Cpolygon fill='%23ECF3FB' points='-0.0972,721.8658 -0.0972,771.6314 79.2486,763.733 83.8885,713.5055 '/%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3C/svg%3E");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left top;
  display: inline-block; }
