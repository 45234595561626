.news-card {
  position: relative;
  height: 350px;
  max-height: 350px;
  z-index: 1;
  color: inherit;
  text-decoration: none;
  display: block; }
  .news-card:hover .news-card__article-content .news-card__category, .news-card:hover .news-card__article-content .news-card__category--blue, .news-card:hover .news-card__article-content .news-card__category--green {
    top: -30px; }

.news-card__img-wrapper {
  position: relative;
  max-height: 350px;
  height: 350px;
  background-repeat: no-repeat;
  background-size: cover; }

.news-card__category, .news-card__category--blue, .news-card__category--green {
  position: absolute;
  top: -4px;
  left: 0;
  width: 190px;
  height: 25px;
  padding: 5px 0px 0 5px;
  overflow-y: hidden;
  transition: top 0.4s;
  -webkit-transition: top 0.4s;
  z-index: -1;
  color: #FFF; }
  .news-card__category--blue {
    background-color: #004F9F; }
  .news-card__category--green {
    background-color: #23AE84; }

.news-card__date-circle {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #3D495E;
  background-color: #FFF;
  border-radius: 50px;
  height: 50px;
  width: 50px;
  text-align: center;
  display: inline-block;
  box-sizing: border-box;
  font-weight: bold;
  font-size: 0.9em;
  padding: 7px;
  line-height: 1.1;
  text-transform: uppercase; }

.news-card__article-headline {
  padding: 10px 15px;
  background-color: #FFF;
  z-index: 2;
  width: 100%;
  box-sizing: border-box;
  text-align: left; }
  .news-card__article-headline p {
    font-weight: bold; }

.news-card__article-description {
  padding: 5px 15px;
  display: none;
  text-align: left; }

.news-card__article-content {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #FFF;
  z-index: 3;
  text-align: left; }
  .news-card__article-content p {
    margin: 0; }
