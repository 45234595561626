.mdc-fab__container {
  z-index: 3; }
  .mdc-fab__container .mdc-fab__button-group {
    height: 50px;
    width: 50px;
    position: fixed;
    bottom: 2em;
    right: 2em;
    z-index: 3; }
    .mdc-fab__container .mdc-fab__button-group a,
    .mdc-fab__container .mdc-fab__button-group svg {
      height: 50px;
      width: 50px;
      vertical-align: middle; }
    .mdc-fab__container .mdc-fab__button-group a {
      border-radius: 50%;
      color: rgba(0, 0, 0, 0.65);
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      transition: 0.4s transform cubic-bezier(0.45, 0, 0.48, 0.99), 0.2s 0.1s opacity, 0.15s background-color ease-in-out, 0.4s box-shadow; }
      .mdc-fab__container .mdc-fab__button-group a:hover {
        box-shadow: 0 7px 12px 1px rgba(0, 0, 0, 0.15); }
      .mdc-fab__container .mdc-fab__button-group a:focus {
        outline: none; }
      .mdc-fab__container .mdc-fab__button-group a.primary {
        height: 60px;
        width: 60px;
        background: #ffd500;
        border-color: #ffd500;
        color: #0b1742;
        z-index: 4; }
        .mdc-fab__container .mdc-fab__button-group a.primary svg {
          line-height: 2;
          height: 1.3em;
          width: 1.3em; }
      .mdc-fab__container .mdc-fab__button-group a span {
        background-color: #ffffff;
        color: #000000;
        display: inline-block;
        padding: .5em 1em;
        margin-right: 1em;
        position: absolute;
        transition: transform .4s;
        white-space: nowrap;
        right: 3em;
        text-align: right;
        top: 0.3em;
        border-radius: 5px; }
      .mdc-fab__container .mdc-fab__button-group a i {
        transition: transform .4s; }
      .mdc-fab__container .mdc-fab__button-group a:not(.primary) {
        opacity: 0;
        right: -5px; }
  .mdc-fab__container.active {
    height: 100%;
    width: 100%;
    position: fixed; }
    .mdc-fab__container.active a.primary i {
      transform: rotate(45deg); }
    .mdc-fab__container.active a:not(.primary):nth-of-type(1) {
      transform: translateY(0px);
      transition: 0.15s 0s transform cubic-bezier(0.45, 0, 0.48, 0.99), 0.1s opacity, 0.15s background-color ease-in-out, 0.4s box-shadow;
      opacity: 1;
      z-index: 3; }
    .mdc-fab__container.active a:not(.primary):nth-of-type(2) {
      transform: translateY(-70px);
      transition: 0.25s 0.015s transform cubic-bezier(0.45, 0, 0.48, 0.99), 0.1s opacity, 0.15s background-color ease-in-out, 0.4s box-shadow;
      opacity: 1;
      z-index: 2; }
    .mdc-fab__container.active a:not(.primary):nth-of-type(3) {
      transform: translateY(-140px);
      transition: 0.35s 0.03s transform cubic-bezier(0.45, 0, 0.48, 0.99), 0.1s opacity, 0.15s background-color ease-in-out, 0.4s box-shadow;
      opacity: 1;
      z-index: 1; }
    .mdc-fab__container.active a:not(.primary):nth-of-type(4) {
      transform: translateY(-210px);
      transition: 0.45s 0.045s transform cubic-bezier(0.45, 0, 0.48, 0.99), 0.1s opacity, 0.15s background-color ease-in-out, 0.4s box-shadow;
      opacity: 1;
      z-index: 0; }
    .mdc-fab__container.active a:not(.primary):nth-of-type(5) {
      transform: translateY(-280px);
      transition: 0.55s 0.06s transform cubic-bezier(0.45, 0, 0.48, 0.99), 0.1s opacity, 0.15s background-color ease-in-out, 0.4s box-shadow;
      opacity: 1;
      z-index: -1; }
    .mdc-fab__container.active a:not(.primary):nth-of-type(6) {
      transform: translateY(-350px);
      transition: 0.65s 0.075s transform cubic-bezier(0.45, 0, 0.48, 0.99), 0.1s opacity, 0.15s background-color ease-in-out, 0.4s box-shadow;
      opacity: 1;
      z-index: -2; }
    .mdc-fab__container.active .mdc-fab__container-backdrop {
      transition: background-color .4s linear;
      background-color: rgba(0, 0, 0, 0.6);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }

@media (max-width: 599px) {
  body.mdc-header-fab-scroll-lock {
    overflow: hidden; } }
