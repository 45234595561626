input.parsley-success,
select.parsley-success,
textarea.parsley-success {
  background-color: transparent; }

.parsley-errors-list li {
  color: #DE6565; }

.step-2-form-group.parsley-error,
.survey-step-3__form-group.parsley-error {
  border: 1px solid #EA4335; }
  .step-2-form-group.parsley-error .parsley-errors-list li,
  .survey-step-3__form-group.parsley-error .parsley-errors-list li {
    padding-left: 1.2em;
    background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='utf-8'?%3E %3C!-- Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E %3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 14 14' enable-background='new 0 0 14 14' xml:space='preserve'%3E %3Cg%3E %3Cg%3E %3Ccircle fill='%23EA4335' cx='7' cy='7' r='7'/%3E %3C/g%3E %3Cg%3E %3Cpath fill='%23FFFFFF' d='M9.4448,9.8567c-0.1054,0-0.2107-0.0402-0.2911-0.1206L4.264,4.8464 c-0.1608-0.1608-0.1608-0.4214,0-0.5823c0.1608-0.1608,0.4214-0.1608,0.5823,0L9.736,9.1538c0.1608,0.1608,0.1608,0.4214,0,0.5823 C9.6556,9.8165,9.5502,9.8567,9.4448,9.8567z'/%3E %3Cpath fill='%23FFFFFF' d='M4.5551,9.8567c-0.1054,0-0.2107-0.0402-0.2911-0.1206c-0.1608-0.1608-0.1608-0.4214,0-0.5823 l4.8897-4.8897c0.1608-0.1608,0.4214-0.1608,0.5823,0c0.1608,0.1608,0.1608,0.4214,0,0.5823L4.8463,9.7361 C4.7658,9.8165,4.6605,9.8567,4.5551,9.8567z'/%3E %3C/g%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3C/svg%3E");
    background-repeat: no-repeat;
    color: #DE6565; }
