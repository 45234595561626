.social-media-icon-bar__container {
  position: relative;
  width: 100%; }

.social-media-icon-bar__icon-list {
  display: flex;
  align-items: stretch;
  /* Default */
  justify-content: space-between;
  margin: 0;
  padding: 0;
  width: 100%; }

.social-media-icon-bar__icon-list-item {
  position: relative;
  display: inline-block;
  width: 3em;
  height: 4em;
  flex: 1 0 auto;
  /* Default */
  list-style-type: none;
  text-align: center; }

.social-media-icon-bar__icon-list-item svg {
  height: 100%; }

.social-media-icon-bar__icon-list #youtube_icon:hover .st0 {
  fill: #FE0000; }

.social-media-icon-bar__icon-list #twitter_icon:hover .st0 {
  fill: #1DA0F1; }

.social-media-icon-bar__icon-list #facebook_icon:hover .st0 {
  fill: #004F9F; }

.social-media-icon-bar__icon-list #google_icon:hover .st0 {
  fill: #DF4F3C; }

.social-media-icon-bar__icon-list #linked_in_icon:hover .st0 {
  fill: #0077B4; }

@media (max-width: 849px) {
  .social-media-icon-bar__icon-list-item {
    height: 2.5em; } }
