/*Responsive Utilities*/
/* Desktop */
@media (min-width: 840px) {
  .os-helper--hide-desktop {
    display: none !important; } }

@media (min-width: 1920px) {
  .os-helper--hide-desktop-xlarge {
    display: none !important; } }

@media (min-width: 1440px) and (max-width: 1919px) {
  .os-helper--hide-desktop-large {
    display: none !important; } }

@media (min-width: 1024px) and (max-width: 1439px) {
  .os-helper--hide-desktop-medium {
    display: none !important; } }

@media (min-width: 840px) and (max-width: 1023px) {
  .os-helper--hide-desktop-small {
    display: none !important; } }

/* Tablet */
@media (min-width: 480px) and (max-width: 839px) {
  .os-helper--hide-tablet {
    display: none !important; } }

@media (min-width: 720px) and (max-width: 839px) {
  .os-helper--hide-tablet-large {
    display: none !important; } }

@media (min-width: 600px) and (max-width: 719px) {
  .os-helper--hide-tablet-medium {
    display: none !important; } }

@media (min-width: 480px) and (max-width: 599px) {
  .os-helper--hide-tablet-small {
    display: none !important; } }

/* Phone */
@media (max-width: 479px) {
  .os-helper--hide-phone {
    display: none !important; } }

@media (min-width: 400px) and (max-width: 479px) {
  .os-helper--hide-phone-large {
    display: none !important; } }

@media (min-width: 360px) and (max-width: 399px) {
  .os-helper--hide-phone-medium {
    display: none !important; } }

@media (max-width: 359px) {
  .os-helper--hide-phone-small {
    display: none !important; } }
