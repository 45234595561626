.footer-contact-tab__control a {
  text-align: left;
  display: inline-block;
  min-width: 4em;
  margin-right: 0.5em; }

.footer-contact-tab__content {
  width: 100%;
  alignt-items: left !important;
  justify-content: left !important; }

.footer-contact-tab__text {
  color: #23AD84 !important; }

.mdc-tab .mdc-tab__text-label {
  text-transform: none;
  opacity: 1; }

.mdc-tab:not(.mdc-tab--active) .footer-contact-tab__text {
  color: #0b1742 !important; }

.footer-contact-tab__content .os-footer .mdc-tab-indicator__content--underline {
  background-color: #23AD84 !important; }

.footer-contact__card address {
  font-style: normal; }

@media (max-width: 479px) {
  .footer-contact__card {
    text-align: center !important; }
  .footer-contact-tab__control a {
    width: 49%;
    margin-right: 0;
    text-align: center; } }
