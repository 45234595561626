.partner-modal-container {
  text-align: center; }
  .partner-modal-container section {
    border: none; }
  .partner-modal-container .close-button-container {
    padding: 1em; }
    .partner-modal-container .close-button-container .close-button {
      display: flex;
      justify-content: end; }
      .partner-modal-container .close-button-container .close-button svg {
        color: #004f9f; }
  .partner-modal-container .partner-img-container svg {
    width: 100%; }
  .partner-modal-container .partner-modal-body {
    text-align: left; }
  .partner-modal-container .redirect-button {
    height: auto;
    padding: 0.5em 0.75em 0.5em 0.75em; }
