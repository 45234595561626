body {
  display: flex;
  position: relative;
  flex-direction: column;
  /*min-height: 100%;*/ }

.content {
  flex: 1 0 auto !important; }

.os-footer {
  background-color: #ffffff; }

.os-footer a {
  padding: 0 !important; }

.os-footer {
  flex-shrink: 0 !important;
  padding-top: 1em;
  background-color: #FFF; }

.os-footer__content {
  max-width: 1220px;
  border-bottom: 1px solid #809AB5;
  font-size: 0.9em; }

.os-footer .mdc-tab__ripple::before, .mdc-tab__ripple::after {
  background-color: white !important; }

.os-footer .mdc-tab-indicator > .mdc-tab-indicator__content--underline {
  background-color: #23AD84; }

.os-footer .mdc-tab-indicator {
  height: 80%; }

@media (max-width: 479px) {
  .os-footer .mdc-tab__content {
    width: 100%;
    align-items: center !important;
    justify-content: center !important; } }
