.bottom-contact {
  background-color: #0E2050;
  margin-top: -1px; }
  @media (max-width: 599px) {
    .bottom-contact {
      padding-top: 2em; } }
  .bottom-contact > .mdc-layout-grid {
    max-width: 1280px; }
  .bottom-contact .bottom-contact__title {
    text-align: center;
    color: #FFF; }
  .bottom-contact .bottom-contact__subtitle {
    text-align: center;
    color: #FFF; }
  .bottom-contact .bottom-contact__icon-column {
    position: relative; }
    @media (max-width: 599px) {
      .bottom-contact .bottom-contact__icon-column {
        text-align: center; } }
  .bottom-contact .bottom-contact__icon-column_right {
    text-align: right; }
    @media (max-width: 599px) {
      .bottom-contact .bottom-contact__icon-column_right {
        text-align: center; } }
  .bottom-contact .bottom-contact__mobile-section {
    display: inline-block;
    width: 100%;
    max-width: 17.7em;
    height: 11.148em;
    text-align: center; }
    .bottom-contact .bottom-contact__mobile-section svg {
      height: 5.8599em;
      margin-bottom: 0.9em; }
  .bottom-contact .bottom-contact__support-section {
    display: inline-block;
    width: 100%;
    max-width: 17.7em;
    height: 11.148em;
    text-align: center; }
    .bottom-contact .bottom-contact__support-section svg {
      height: 5.8599em;
      margin-bottom: 0.9em; }
  .bottom-contact .bottom-contact .mdc-button {
    width: 100%; }
