.bottom-subscribe-newsletter {
  box-sizing: border-box;
  background-color: #0E2050;
  width: 100%;
  margin-top: -1px; }
  .bottom-subscribe-newsletter .mdc-layout-grid {
    width: 100%;
    max-width: 1280px; }
  .bottom-subscribe-newsletter .bottom-subscribe-newsletter__title {
    text-align: center; }
  .bottom-subscribe-newsletter .bottom-subscribe-newsletter__subtitle {
    text-align: center; }
  .bottom-subscribe-newsletter .bottom-subscribe-newsletter__control-section {
    padding-bottom: 2em; }
  .bottom-subscribe-newsletter .bottom-subscribe-newsletter__success-newsletter-section {
    display: none;
    text-align: center;
    padding-bottom: 2em; }
    .bottom-subscribe-newsletter .bottom-subscribe-newsletter__success-newsletter-section svg {
      display: block;
      width: 3.625em;
      height: 3.625em;
      margin: 0 auto 1em auto; }
    .bottom-subscribe-newsletter .bottom-subscribe-newsletter__success-newsletter-section span {
      font-family: 'Roboto-Regular';
      font-size: 1em;
      color: #82A0BE; }
  .bottom-subscribe-newsletter .mdc-text-field {
    background-color: #FFF;
    border-radius: 4px;
    height: 3.125em !important;
    padding: 0 1em; }
    .bottom-subscribe-newsletter .mdc-text-field .mdc-text-field__input {
      padding-top: 0 !important;
      color: #004f9f; }
      .bottom-subscribe-newsletter .mdc-text-field .mdc-text-field__input::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        color: #82A0BE; }
      .bottom-subscribe-newsletter .mdc-text-field .mdc-text-field__input:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #82A0BE;
        opacity: 1; }
      .bottom-subscribe-newsletter .mdc-text-field .mdc-text-field__input::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #82A0BE;
        opacity: 1; }
      .bottom-subscribe-newsletter .mdc-text-field .mdc-text-field__input:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #82A0BE; }
  .bottom-subscribe-newsletter .bottom-subscribe-newsletter__error-message {
    display: none;
    margin-top: 0.5em;
    font-family: 'Roboto-Regular';
    color: #82A0BE;
    line-height: 1.1875em; }
    .bottom-subscribe-newsletter .bottom-subscribe-newsletter__error-message svg {
      width: 1.4375em;
      margin-right: 0.5em;
      position: relative;
      top: 0.3em; }
  .bottom-subscribe-newsletter .bottom-subscribe-newsletter__button-container {
    text-align: center; }
  .bottom-subscribe-newsletter .mdc-button {
    font-family: 'Poppins-Medium';
    font-size: 1em;
    height: 3.125em;
    width: 100%; }
    @media (max-width: 1023px) {
      .bottom-subscribe-newsletter .mdc-button {
        width: auto; } }
