@font-face {
  font-family: 'Roboto-BoldItalic';
  src: url("/font/roboto/Roboto-BoldItalic.eot");
  src: url("/font/roboto/Roboto-BoldItalic.eot?#iefix") format("embedded-opentype"), url("/font/roboto/Roboto-BoldItalic.woff") format("woff"), url("/font/roboto/Roboto-BoldItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-BlackItalic';
  src: url("/font/roboto/Roboto-BlackItalic.eot");
  src: url("/font/roboto/Roboto-BlackItalic.eot?#iefix") format("embedded-opentype"), url("/font/roboto/Roboto-BlackItalic.woff") format("woff"), url("/font/roboto/Roboto-BlackItalic.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: 'Roboto-Light';
  src: url("/font/roboto/Roboto-Light.eot");
  src: url("/font/roboto/Roboto-Light.eot?#iefix") format("embedded-opentype"), url("/font/roboto/Roboto-Light.woff") format("woff"), url("/font/roboto/Roboto-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-Thin';
  src: url("/font/roboto/Roboto-Thin.eot");
  src: url("/font/roboto/Roboto-Thin.eot?#iefix") format("embedded-opentype"), url("/font/roboto/Roboto-Thin.woff") format("woff"), url("/font/roboto/Roboto-Thin.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-MediumItalic';
  src: url("/font/roboto/Roboto-MediumItalic.eot");
  src: url("/font/roboto/Roboto-MediumItalic.eot?#iefix") format("embedded-opentype"), url("/font/roboto/Roboto-MediumItalic.woff") format("woff"), url("/font/roboto/Roboto-MediumItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-Bold';
  src: url("/font/roboto/Roboto-Bold.eot");
  src: url("/font/roboto/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("/font/roboto/Roboto-Bold.woff") format("woff"), url("/font/roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-LightItalic';
  src: url("/font/roboto/Roboto-LightItalic.eot");
  src: url("/font/roboto/Roboto-LightItalic.eot?#iefix") format("embedded-opentype"), url("/font/roboto/Roboto-LightItalic.woff") format("woff"), url("/font/roboto/Roboto-LightItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-Medium';
  src: url("/font/roboto/Roboto-Medium.eot");
  src: url("/font/roboto/Roboto-Medium.eot?#iefix") format("embedded-opentype"), url("/font/roboto/Roboto-Medium.woff") format("woff"), url("/font/roboto/Roboto-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-Regular';
  src: url("/font/roboto/Roboto-Regular.eot");
  src: url("/font/roboto/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("/font/roboto/Roboto-Regular.woff") format("woff"), url("/font/roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-ThinItalic';
  src: url("/font/roboto/Roboto-ThinItalic.eot");
  src: url("/font/roboto/Roboto-ThinItalic.eot?#iefix") format("embedded-opentype"), url("/font/roboto/Roboto-ThinItalic.woff") format("woff"), url("/font/roboto/Roboto-ThinItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-Black';
  src: url("/font/roboto/Roboto-Black.eot");
  src: url("/font/roboto/Roboto-Black.eot?#iefix") format("embedded-opentype"), url("/font/roboto/Roboto-Black.woff") format("woff"), url("/font/roboto/Roboto-Black.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-Italic';
  src: url("/font/roboto/Roboto-Italic.eot");
  src: url("/font/roboto/Roboto-Italic.eot?#iefix") format("embedded-opentype"), url("/font/roboto/Roboto-Italic.woff") format("woff"), url("/font/roboto/Roboto-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-Light';
  src: url("/font/poppins/Poppins-Light.eot");
  src: url("/font/poppins/Poppins-Light.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-Light.woff") format("woff"), url("/font/poppins/Poppins-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-SemiBold';
  src: url("/font/poppins/Poppins-SemiBold.eot");
  src: url("/font/poppins/Poppins-SemiBold.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-SemiBold.woff") format("woff"), url("/font/poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-ExtraLight';
  src: url("/font/poppins/Poppins-ExtraLight.eot");
  src: url("/font/poppins/Poppins-ExtraLight.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-ExtraLight.woff") format("woff"), url("/font/poppins/Poppins-ExtraLight.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-Italic';
  src: url("/font/poppins/Poppins-Italic.eot");
  src: url("/font/poppins/Poppins-Italic.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-Italic.woff") format("woff"), url("/font/poppins/Poppins-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-Bold';
  src: url("/font/poppins/Poppins-Bold.eot");
  src: url("/font/poppins/Poppins-Bold.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-Bold.woff") format("woff"), url("/font/poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-ExtraBoldItalic';
  src: url("/font/poppins/Poppins-ExtraBoldItalic.eot");
  src: url("/font/poppins/Poppins-ExtraBoldItalic.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-ExtraBoldItalic.woff") format("woff"), url("/font/poppins/Poppins-ExtraBoldItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-ExtraBold';
  src: url("/font/poppins/Poppins-ExtraBold.eot");
  src: url("/font/poppins/Poppins-ExtraBold.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-ExtraBold.woff") format("woff"), url("/font/poppins/Poppins-ExtraBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-ExtraLightItalic';
  src: url("/font/poppins/Poppins-ExtraLightItalic.eot");
  src: url("/font/poppins/Poppins-ExtraLightItalic.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-ExtraLightItalic.woff") format("woff"), url("/font/poppins/Poppins-ExtraLightItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-Medium';
  src: url("/font/poppins/Poppins-Medium.eot");
  src: url("/font/poppins/Poppins-Medium.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-Medium.woff") format("woff"), url("/font/poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-Black';
  src: url("/font/poppins/Poppins-Black.eot");
  src: url("/font/poppins/Poppins-Black.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-Black.woff") format("woff"), url("/font/poppins/Poppins-Black.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-BlackItalic';
  src: url("/font/poppins/Poppins-BlackItalic.eot");
  src: url("/font/poppins/Poppins-BlackItalic.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-BlackItalic.woff") format("woff"), url("/font/poppins/Poppins-BlackItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-BoldItalic';
  src: url("/font/poppins/Poppins-BoldItalic.eot");
  src: url("/font/poppins/Poppins-BoldItalic.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-BoldItalic.woff") format("woff"), url("/font/poppins/Poppins-BoldItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-ThinItalic';
  src: url("/font/poppins/Poppins-ThinItalic.eot");
  src: url("/font/poppins/Poppins-ThinItalic.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-ThinItalic.woff") format("woff"), url("/font/poppins/Poppins-ThinItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-Thin';
  src: url("/font/poppins/Poppins-Thin.eot");
  src: url("/font/poppins/Poppins-Thin.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-Thin.woff") format("woff"), url("/font/poppins/Poppins-Thin.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-MediumItalic';
  src: url("/font/poppins/Poppins-MediumItalic.eot");
  src: url("/font/poppins/Poppins-MediumItalic.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-MediumItalic.woff") format("woff"), url("/font/poppins/Poppins-MediumItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-Regular';
  src: url("/font/poppins/Poppins-Regular.eot");
  src: url("/font/poppins/Poppins-Regular.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-Regular.woff") format("woff"), url("/font/poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-SemiBoldItalic';
  src: url("/font/poppins/Poppins-SemiBoldItalic.eot");
  src: url("/font/poppins/Poppins-SemiBoldItalic.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-SemiBoldItalic.woff") format("woff"), url("/font/poppins/Poppins-SemiBoldItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-LightItalic';
  src: url("/font/poppins/Poppins-LightItalic.eot");
  src: url("/font/poppins/Poppins-LightItalic.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-LightItalic.woff") format("woff"), url("/font/poppins/Poppins-LightItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

.job-posting-page__content {
  position: relative;
  margin-top: 16em; }
  @media (max-width: 849px) {
    .job-posting-page__content {
      margin-top: 12em; } }
  @media (max-width: 479px) {
    .job-posting-page__content {
      margin-top: 10em; } }

.job-posting-page__content .mdc-layout-grid {
  max-width: 1280px;
  margin: 0 auto; }

.job-page__back-button {
  position: relative;
  font-family: 'Poppins Regular';
  font-size: 1.1em;
  padding-left: 0.8em;
  line-height: 2.2em;
  color: white;
  top: -2em;
  left: 5em;
  width: 15em;
  height: 3em;
  z-index: -2;
  background: url("data:image/svg+xml,%3C?xml version='1.0' encoding='utf-8'?%3E %3C!-- Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E %3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 264.676 54.1285' style='enable-background:new 0 0 264.676 54.1285;' xml:space='preserve' preserveAspectRatio='xMinYMax'%3E %3Cstyle type='text/css'%3E .st0%7Bfill:%2323AE84;%7D %3C/style%3E %3Cpolygon class='st0' points='229.9981,54.1285 0,54.1285 0,0.8536 264.676,0 '/%3E %3C/svg%3E");
  background-size: 100%;
  background-repeat: no repeat;
  background-position: left bottom; }
