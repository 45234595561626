.slide-wrap-real-estate, .slide-wrap-child-care, .slide-wrap-driving-school {
  max-width: 60em;
  margin: 0 auto;
  position: relative; }
  .slide-wrap-real-estate .slick-next, .slide-wrap-real-estate .slick-prev, .slide-wrap-child-care .slick-next, .slide-wrap-child-care .slick-prev, .slide-wrap-driving-school .slick-next, .slide-wrap-driving-school .slick-prev {
    position: absolute;
    display: inline-block;
    top: 1.23em;
    z-index: 3;
    width: 3.6em;
    height: 16.3em; }
    .slide-wrap-real-estate .slick-next:hover, .slide-wrap-real-estate .slick-prev:hover, .slide-wrap-child-care .slick-next:hover, .slide-wrap-child-care .slick-prev:hover, .slide-wrap-driving-school .slick-next:hover, .slide-wrap-driving-school .slick-prev:hover {
      cursor: pointer; }
  .slide-wrap-real-estate .slick-next, .slide-wrap-child-care .slick-next, .slide-wrap-driving-school .slick-next {
    background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.25) 0%, rgba(125, 185, 232, 0) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.25) 0%, rgba(125, 185, 232, 0) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, rgba(0, 0, 0, 0.25) 0%, rgba(125, 185, 232, 0) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#66000000', endColorstr='#007db9e8',GradientType=1 );
    /* IE6-9 */
    right: 0; }
  .slide-wrap-real-estate .slick-prev, .slide-wrap-child-care .slick-prev, .slide-wrap-driving-school .slick-prev {
    background: -moz-linear-gradient(left, rgba(125, 185, 232, 0) 0%, rgba(0, 0, 0, 0.25) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgba(125, 185, 232, 0) 0%, rgba(0, 0, 0, 0.25) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, rgba(125, 185, 232, 0) 0%, rgba(0, 0, 0, 0.25) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#007db9e8', endColorstr='#66000000',GradientType=1 );
    /* IE6-9 */
    left: 0; }
  .slide-wrap-real-estate .slide-wrapper, .slide-wrap-child-care .slide-wrapper, .slide-wrap-driving-school .slide-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; }
  .slide-wrap-real-estate .slick-track, .slide-wrap-child-care .slick-track, .slide-wrap-driving-school .slick-track {
    margin-top: .1em;
    height: 18.5em; }
