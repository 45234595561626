.widget-phone-mdc {
  background-color: transparent;
  border: none;
  position: relative;
  margin: 1.5em 0; }

.widget-phone-mdc .widget-phone-wrapper {
  display: inline-block;
  position: relative; }

.widget-phone-mdc .widget-phone-mdc-tooltip {
  display: none;
  position: absolute;
  z-index: 9999;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  line-break: auto;
  right: 0;
  top: 0;
  width: 200px;
  padding: 3px 8px;
  text-align: center;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); }

.widget-phone-mdc button {
  float: left;
  height: 56px;
  border-right: rgba(0, 0, 0, 0.1) 1px solid;
  border-bottom: rgba(0, 0, 0, 0.4) 1px solid;
  background-color: rgba(0, 0, 0, 0.02) !important;
  color: rgba(0, 0, 0, 0.6) !important;
  padding-right: 0px;
  -webkit-border-top-right-radius: 0px;
  -webkit-border-bottom-right-radius: 0px;
  -moz-border-radius-topright: 0px;
  -moz-border-radius-bottomright: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  -webkit-border-top-left-radius: 2px;
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius-topleft: 2px;
  -moz-border-radius-bottomleft: 2px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px; }

.widget-phone-mdc button .mdc-button__icon {
  margin-right: 5px;
  width: 16px;
  height: 11px;
  background: url(/image/phone-widget/sprite.png);
  display: inline-block; }

.widget-phone-mdc .mdc-menu {
  position: absolute;
  left: 0;
  max-height: 300px; }

.widget-phone-mdc .mdc-menu li > .country-icon {
  display: inline-block;
  margin-right: 12px;
  width: 16px;
  height: 11px;
  background: url(/image/phone-widget/sprite.png); }

.widget-phone-mdc .mdc-text-field {
  float: left;
  margin: 0 !important;
  /*overflow: initial;*/
  -webkit-border-top-left-radius: 0px;
  -webkit-border-bottom-left-radius: 0px;
  -moz-border-radius-topleft: 0px;
  -moz-border-radius-bottomleft: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  -webkit-border-top-right-radius: 2px;
  -webkit-border-bottom-right-radius: 2px;
  -moz-border-radius-topright: 2px;
  -moz-border-radius-bottomright: 2px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px; }
  @media (max-width: 361px) {
    .widget-phone-mdc .mdc-text-field {
      width: 80%; } }

.widget-phone-mdc .os-text-field .mdc-text-field__icon {
  top: 0.8em; }
