html, body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative; }

hr {
  border: 0;
  width: 2.5em;
  height: 0.25em;
  background-color: #23ae84;
  margin: 1em 0;
  text-align: left; }

p {
  margin: 0 0 1.5em 0; }
