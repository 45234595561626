.job-posting-info__container {
  padding: 4em 2em 2em 2em;
  background-color: white; }

.job-posting-info__description {
  line-height: 1.6em; }

.job-posting-info__section-header {
  text-transform: uppercase;
  margin: 2em 0 0.75em 0;
  font-size: 1.15em;
  font-family: 'Poppins-Medium';
  color: #3d495e; }

.job-posting-info__section-list {
  margin-top: 0em;
  padding-left: 0; }

.job-posting-info__section-list li {
  margin: 0;
  font-family: 'Roboto-Light';
  margin-bottom: 0.35em;
  font-size: 1em;
  margin-left: 2em; }

.job-page__section-subheader {
  font-size: 1.05em;
  margin: 2em 0 0.5em 0;
  font-family: 'Poppins-Medium';
  color: #3d495e; }

.job-posting-info__container .os-button--action {
  margin-top: 1em;
  width: 18em; }
