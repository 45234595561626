.os-select.mdc-select:not(.mdc-select--disabled) {
  background-color: transparent;
  background-color: #eaf1fa; }

.os-select.mdc-select:not(.mdc-select--disabled) .mdc-select__native-control:focus ~ .mdc-line-ripple {
  background-color: #004f9f; }

.os-select.mdc-select--outlined:not(.mdc-select--disabled) .mdc-select__native-control:focus ~ .mdc-notched-outline .mdc-notched-outline__path {
  stroke: #004f9f; }

.os-select.mdc-select:not(.mdc-select--disabled) .mdc-select__native-control {
  -moz-appearance: none;
  -webkit-appearance: none; }

.os-select.mdc-select:not(.mdc-select--disabled) .mdc-select__native-control:focus ~ .mdc-floating-label {
  color: #004f9f; }

.os-select.mdc-select--with-leading-icon .mdc-floating-label {
  left: 45px; }

.os-select.mdc-select--with-leading-icon .mdc-floating-label--float-above {
  left: 16px; }

.os-select.mdc-select--with-leading-icon select {
  position: relative;
  text-indent: 0.8em; }

.os-select.mdc-select .mdc-select__icon {
  position: absolute;
  bottom: 16px;
  left: 15px; }

.os-select.mdc-select .mdc-select__icon svg {
  width: 1.2750em; }

@media all and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  .os-select.mdc-select--with-leading-icon select {
    text-indent: 1.8em; } }

@supports (-ms-ime-align: auto) {
  .os-select.mdc-select--with-leading-icon select {
    padding-left: 2.65em !important; }
  .os-select.mdc-select--with-leading-icon select option {
    padding-left: 0 !important; } }

@supports (display: -ms-flexbox) {
  .os-select.mdc-select--with-leading-icon select {
    padding-left: 2.65em !important; }
  .os-select.mdc-select--with-leading-icon select option {
    padding-left: 0 !important; } }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .os-select.mdc-select--with-leading-icon select {
    padding-left: 2.65em !important; }
  .os-select.mdc-select--with-leading-icon select option {
    padding-left: 0 !important; }
  .os-select.mdc-select .mdc-select__icon {
    position: absolute;
    bottom: -50px; } }
