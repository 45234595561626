a {
  text-decoration: none;
  color: inherit; }

.footer-cell__list {
  list-style-type: none;
  list-style-position: inside;
  padding: 0; }
  .footer-cell__list.footer-cell__list-half {
    float: left;
    width: 50%; }

.footer-cell__icon-half {
  float: left;
  width: 50%; }
  .footer-cell__icon-half svg {
    width: 4.5em;
    height: 7.6632em;
    position: relative;
    top: 1.5em; }

.footer-cell {
  padding: 0;
  margin: 0;
  width: 100%; }
  @media (min-width: 1024px) and (max-width: 1439px) {
    .footer-cell {
      grid-column-end: span 4; } }

.footer-cell__list-item {
  font-family: 'Roboto-Light';
  color: #3D495E;
  font-size: 1em;
  margin-bottom: 0.4em; }

.footer-cell__list-item a:hover {
  color: #23AD84 !important; }

.footer-cell__sub-header {
  color: #004F9E;
  font-family: "Poppins-Regular";
  font-size: 1.25em;
  text-transform: uppercase; }

@media (max-width: 479px) {
  .footer-cell {
    padding: 0; }
  .footer-cell__sub-header {
    text-align: center;
    margin-bottom: 1em;
    font-size: 1.3em; } }
