.pro-cta-section {
  text-align: center; }
  @media (min-width: 320px) {
    .pro-cta-section {
      background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='utf-8'?%3E %3C!-- Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E %3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 600 460' enable-background='new 0 0 600 460' xml:space='preserve'%3E %3Cg%3E %3ClinearGradient id='SVGID_1_' gradientUnits='userSpaceOnUse' x1='307.2227' y1='204.8948' x2='-30.2328' y2='313.4052'%3E %3Cstop offset='0' style='stop-color:%23233267'/%3E %3Cstop offset='1' style='stop-color:%2301519C'/%3E %3C/linearGradient%3E %3Crect x='0' fill='url(%23SVGID_1_)' width='600' height='414.4346'/%3E %3Cpolygon opacity='0.65' fill='%2301519C' points='600,414.4346 600,332.3402 0,460 0,414.4346 '/%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center top;
      background-size: 100%;
      width: 100%;
      height: 16.5em;
      min-height: 16.5em;
      padding-top: 1.5em;
      margin-bottom: -3.8em;
      position: relative;
      z-index: 1; }
      .pro-cta-section .mdc-button--raised:not(:disabled) {
        font-family: 'Poppins-SemiBold';
        background-color: #ffd500;
        color: #0b1742; } }
  @media (min-width: 384px) and (max-width: 411px) {
    .pro-cta-section {
      height: 17em;
      min-height: 17em;
      margin-bottom: -3.5em; } }
  @media (min-width: 412px) and (max-width: 599px) {
    .pro-cta-section {
      height: 19.9em;
      margin-bottom: -5.2em;
      min-height: 19.9em; } }
  @media (min-width: 600px) {
    .pro-cta-section {
      display: none; } }
