@media (min-width: 840px) {
  .header-menu-primary-survey {
    margin-top: 2em; } }

@media (min-width: 480px) and (max-width: 839px) {
  .header-menu-primary-survey {
    margin-top: 4em; } }

.survey-header {
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 48em;
  background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='utf-8'?%3E %3C!-- Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E %3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 1920.5986 771.4928' enable-background='new 0 0 1920.5986 771.4928' xml:space='preserve'%3E %3Cg%3E %3Cpolygon fill='%23DCE7F5' points='0,0 0,771.4928 1920.5986,580.3579 1920.5986,0 '/%3E %3Cg%3E %3Cdefs%3E %3Cpolygon id='SVGID_1_' points='1920.5986,580.358 1920.5986,0 0.6316,0 0.6316,771.4928 '/%3E %3C/defs%3E %3CclipPath id='SVGID_2_'%3E %3Cuse xlink:href='%23SVGID_1_' overflow='visible'/%3E %3C/clipPath%3E %3Cpolygon clip-path='url(%23SVGID_2_)' fill='%23ECF3FB' points='1726.9133,549.9863 1920.7861,530.6855 1920.7861,480.9199 1731.5531,499.7578 '/%3E %3Cpolygon clip-path='url(%23SVGID_2_)' fill='%23C0D5ED' points='1478.6556,574.6788 1474.0157,624.9064 1789.0591,593.5441 1793.699,543.3156 '/%3E %3Cpolygon opacity='0.5' clip-path='url(%23SVGID_2_)' fill='%23C0D5ED' points='475.2773,577.4272 207.3596,604.0991 202.7197,654.3267 470.6375,627.6548 '/%3E %3Cpolygon opacity='0.75' clip-path='url(%23SVGID_2_)' fill='%23ECF3FB' points='124.5253,384.0631 -143.3925,410.735 -148.0324,460.9625 119.8854,434.2906 '/%3E %3Cpolygon clip-path='url(%23SVGID_2_)' fill='%23C0D5ED' points='83.8885,713.5055 79.2486,763.733 308.8045,740.8805 313.4444,690.653 '/%3E %3Cpolygon opacity='0.5' clip-path='url(%23SVGID_2_)' fill='%23C0D5ED' points='1582.5417,514.5916 1731.5157,499.7605 1736.1556,449.533 1587.1816,464.364 '/%3E %3Cpolygon clip-path='url(%23SVGID_2_)' fill='%23ECF3FB' points='-1.5989,722.0153 -6.2388,772.2428 79.2486,763.733 83.8885,713.5055 '/%3E %3Cpolygon opacity='0.75' clip-path='url(%23SVGID_2_)' fill='%23ECF3FB' points='127.1157,562.1141 122.4758,612.3416 207.9632,603.8318 212.6031,553.6043 '/%3E %3C/g%3E %3C/g%3E %3C/svg%3E");
  background-position: center top;
  background-repeat: no-repeat;
  z-index: -1; }
  @media (min-width: 840px) and (max-width: 1023px) {
    .survey-header {
      height: 32em; } }
  @media (min-width: 600px) and (max-width: 839px) {
    .survey-header {
      height: 28em; } }
  @media (max-width: 599px) {
    .survey-header {
      height: 18em; } }

.header__logo {
  position: absolute;
  right: 67%;
  z-index: 5;
  width: 45em;
  height: 12.8563em;
  overflow: hidden; }

.survey-header__certification-logos {
  display: inline-block;
  margin-top: .5em;
  margin-right: .5em;
  position: absolute;
  right: 0; }
  .survey-header__certification-logos svg {
    width: 40px;
    height: 40px; }
  .survey-header__certification-logos img {
    width: 40px;
    height: 34.5px;
    padding-bottom: 2.75px; }
  @media (min-width: 480px) and (max-width: 839px) {
    .survey-header__certification-logos {
      position: relative; }
      .survey-header__certification-logos svg {
        width: 50px;
        height: 50px; }
      .survey-header__certification-logos img {
        width: 50px;
        height: 44.5px;
        padding-bottom: 2.75px; } }
  @media (min-width: 840px) {
    .survey-header__certification-logos {
      position: relative; }
      .survey-header__certification-logos svg {
        width: 70px;
        height: 70px; }
      .survey-header__certification-logos img {
        width: 70px;
        height: 64.5px;
        padding-bottom: 2.75px; } }

.survey-header-grid {
  max-width: 1280px; }

.headline-title {
  color: #102D69;
  font-family: 'Poppins-Medium';
  font-size: 1.5714em;
  line-height: 1em;
  text-transform: uppercase;
  padding-top: 0.5em; }
  @media (min-width: 1024px) and (max-width: 1439px) {
    .headline-title {
      font-size: 1em;
      padding: 1em 0 0 3em; } }
  @media (min-width: 840px) and (max-width: 1023px) {
    .headline-title {
      font-size: 1em;
      padding: 1em 0 0 3em; } }

.headline-subtitle {
  color: #102D69;
  font-family: 'Poppins-Medium';
  font-size: 1.5714em;
  text-transform: uppercase; }
  @media (min-width: 1024px) and (max-width: 1439px) {
    .headline-subtitle {
      font-size: 1em;
      padding: 0 0 0 3em; } }
  @media (min-width: 840px) and (max-width: 1023px) {
    .headline-subtitle {
      font-size: 1em;
      padding: 0 0 0 3em; } }
