.message-bar__container {
  display: none;
  position: fixed;
  width: 100%;
  background-color: #23ae84;
  z-index: 6; }
  @media (max-width: 479px) {
    .message-bar__container {
      padding-top: 0.25em;
      padding-bottom: 0.25em; } }

.message-bar__container p {
  display: inline-block;
  margin: 0; }

.message-bar__catch {
  margin-right: 1em; }

.message-bar__text {
  position: relative;
  display: inline-block;
  height: 100%;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='utf-8'?%3E %3C!-- Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E %3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 731.5485 75' style='enable-background:new 0 0 731.5485 75;' xml:space='preserve'%3E %3Cstyle type='text/css'%3E .st0%7Bfill:%23008D61;%7D %3C/style%3E %3Cpolygon class='st0' points='731.5485,0 16.5209,0 0,75 715.0275,75 '/%3E %3C/svg%3E");
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat; }
  @media (max-width: 699px) {
    .message-bar__text {
      background-image: none;
      text-align: center; } }

.message-bar__text p {
  padding-left: 1em;
  padding-right: 1em; }
  @media (max-width: 479px) {
    .message-bar__text p {
      font-size: 1.1em;
      margin-bottom: 0.5em; } }

.message-bar__dark-green {
  position: absolute;
  top: 0;
  left: 22em;
  height: 100%;
  width: 36em;
  z-index: -1; }

.message-bar {
  max-width: 1280px;
  margin: 0 auto; }
  .message-bar > svg {
    width: 1.6em;
    height: 1.4em;
    position: relative;
    top: 0.2em;
    margin-right: 1em; }
    @media (max-width: 789px) {
      .message-bar > svg {
        display: none; } }
  @media (max-width: 1439px) {
    .message-bar {
      max-width: 80%; } }
  @media (max-width: 479px) {
    .message-bar {
      font-size: 0.8em;
      margin: 0 auto; } }

.message-bar__close-button {
  position: absolute;
  cursor: pointer;
  background-color: transparent;
  border: none;
  right: 3em;
  top: 1em;
  height: 2em;
  width: 2em;
  background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='utf-8'?%3E %3C!-- Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E %3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 19.678 19.678' style='enable-background:new 0 0 19.678 19.678;' xml:space='preserve'%3E %3Cstyle type='text/css'%3E .st0%7Bopacity:0.2;%7D .st1%7Bfill:%23FFFFFF;%7D %3C/style%3E %3Cg%3E %3Cg%3E %3Ccircle class='st0' cx='9.839' cy='9.839' r='9.839'/%3E %3C/g%3E %3Cg%3E %3Cpath class='st1' d='M13.2754,13.8543c-0.1481,0-0.2962-0.0565-0.4092-0.1696L5.9934,6.8119 c-0.2261-0.2261-0.2261-0.5923,0-0.8184c0.2261-0.2261,0.5923-0.2261,0.8184,0l6.8728,6.8728 c0.2261,0.2261,0.2261,0.5923,0,0.8184C13.5716,13.7978,13.4235,13.8543,13.2754,13.8543z'/%3E %3Cpath class='st1' d='M6.4026,13.8543c-0.1481,0-0.2962-0.0565-0.4092-0.1696c-0.2261-0.2261-0.2261-0.5923,0-0.8184l6.8728-6.8728 c0.2261-0.2261,0.5923-0.2261,0.8184,0c0.2261,0.2261,0.2261,0.5923,0,0.8184l-6.8728,6.8728 C6.6988,13.7978,6.5507,13.8543,6.4026,13.8543z'/%3E %3C/g%3E %3C/g%3E %3C/svg%3E");
  position: left top;
  background-size: contain;
  background-repeat: no-repeat; }
  @media (max-width: 479px) {
    .message-bar__close-button {
      height: 1.75em;
      width: 1.75em;
      right: 1em; } }

.message-bar--offset-margin-top.mdc-top-app-bar {
  top: 0px; }
