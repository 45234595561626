.footer-extra {
  max-width: 1300px;
  font-family: 'Roboto-Light';
  color: #3d495e;
  font-size: 1em;
  color: #82A0BE;
  font-size: 0.7em; }

.footer-extra__conditions-item {
  padding-right: 1em;
  padding-left: 1em; }

.footer-extra__conditions-item--bordered {
  border-right: 1px solid #82A0BE;
  border-left: 1px solid #82A0BE; }

@media (min-width: 480px) and (max-width: 839px) {
  .footer-extra__conditions {
    text-align: center; }
  .footer-extra__conditions-item {
    flex: 0 1 auto;
    text-align: center; } }

@media (max-width: 479px) {
  .footer-extra__conditions {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    .footer-extra__conditions .footer-extra__conditions-item {
      padding: 0;
      display: inline-block;
      width: 48%;
      text-align: center; }
    .footer-extra__conditions .footer-extra__conditions-item--bordered {
      border-right: none; } }
