.job-posting-share__card {
  margin-top: 1em;
  padding: 1em;
  background-color: white; }

.job-posting-info__section-subheader.job-posting-share__subheader {
  margin-top: 2em;
  font-size: 1em !important; }

.job-posting-apply-card-header {
  font-family: 'Roboto Medium'; }

.job-posting-apply__social-icons #mail_icon:hover .st0 {
  fill: #FE0000; }

.job-posting-apply__social-icons #twitter_icon:hover .st0 {
  fill: #1DA0F1; }

.job-posting-apply__social-icons #facebook_icon:hover .st0 {
  fill: #004F9F; }

.job-posting-apply__social-icons #linked_in_icon:hover .st0 {
  fill: #0077B4; }
