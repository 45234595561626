.mdc-floating-label {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.00937em;
  text-decoration: inherit;
  text-transform: inherit;
  position: absolute;
  bottom: 8px;
  left: 0;
  transform-origin: left top;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1);
  line-height: 1.15rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: text;
  overflow: hidden;
  will-change: transform; }
  [dir="rtl"] .mdc-floating-label, .mdc-floating-label[dir="rtl"] {
    /* @noflip */
    right: 0;
    /* @noflip */
    left: auto;
    /* @noflip */
    transform-origin: right top; }

.mdc-floating-label--float-above {
  cursor: auto; }

.mdc-floating-label--float-above {
  transform: translateY(-100%) scale(0.75); }

.mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-standard 250ms 1; }

@keyframes mdc-floating-label-shake-float-above-standard {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-100%) scale(0.75); }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    transform: translateX(calc(4% - 0%)) translateY(-100%) scale(0.75); }
  66% {
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    transform: translateX(calc(-4% - 0%)) translateY(-100%) scale(0.75); }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-100%) scale(0.75); } }

.os-floating-label.mdc-floating-label {
  color: rgba(0, 0, 0, 0.87);
  background-color: transparent; }
