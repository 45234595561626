.job-posting-footer__container {
  width: 100%;
  background-color: #0E2050;
  padding: 4em 0 2em 0;
  margin-top: -2em; }

.job-posting-footer__container .mdc-layout-grid {
  text-align: center;
  max-width: 1280px;
  margin: 0 auto; }
