.job-posting-apply__container {
  background-color: white;
  padding: 1.5em;
  position: relative; }

@media (max-width: 479px) {
  .job-posting-apply__button-container {
    text-align: center; } }

.job-posting-apply__container .os-button--action {
  width: 100%;
  margin-top: 1em; }
  @media (min-width: 480px) and (max-width: 839px) {
    .job-posting-apply__container .os-button--action {
      width: 18em; } }
  @media (max-width: 479px) {
    .job-posting-apply__container .os-button--action {
      width: 18em; } }

.job-posting-apply__social-icons {
  list-style-type: none;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  max-width: 18em;
  margin: 0 auto;
  padding: 0; }
  @media (min-width: 480px) and (max-width: 839px) {
    .job-posting-apply__social-icons {
      margin: 0;
      width: 14em; } }
  @media (max-width: 479px) {
    .job-posting-apply__social-icons {
      margin: 0 auto; } }

.job-posting-apply__social-icons li {
  width: 2.25em;
  display: inline-block; }

.job-posting-apply__social-icons li img {
  width: 100%; }
